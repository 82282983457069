<template>
  <b-card>
    <validation-observer
      ref="accountSetup"
      #default="{invalid}"
    >
      <b-form @submit.prevent="onSubmit">
        <b-row>

          <b-col cols="12">
            <!--  user  -->
            <validation-provider
              #default="{ errors }"
              name="User"
              rules="required"
            >
              <entity-select
                v-if="!user"
                v-model="account.user"
                :filter="userFilter"
              />
              <span
                v-else
                class="my-1"
              >{{ user.email }}</span>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>

          <b-col cols="12">
            <b-form-group>
              <label>Product*
                <b-badge
                  v-if="product"
                  variant="light-primary"
                >
                  {{ product.name }}
                  <b-button
                    class="btn-icon rounded-circle"
                    size="sm"
                    variant="light-primary"
                    @click="onDeselectedProduct"
                  >
                    <feather-icon icon="XIcon" />
                  </b-button>
                </b-badge>
              </label>
              <product-tree-select-single
                :disabled="!canSelectProduct"
                @selected="onSelectedProduct"
              />

            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group
              label="Account type*"
            >
              <validation-provider
                #default="{ errors }"
                name="Account type"
                rules="required"
              >
                <account-type-select
                  v-model="account.account_subtype"
                  :disabled="!canCreateCustomAccount"
                  @input="account.currency = null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group
              label="Currency*"
            >
              <validation-provider
                #default="{ errors }"
                name="Currency"
                rules="required"
              >
                <currency-select
                  v-model="account.currency"
                  :account-subtype="account.account_subtype"
                  :disabled="!canCreateCustomAccount"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group
              label="Deposit"
            >
              <validation-provider
                #default="{ errors }"
                name="Deposit*"
                rules="required"
              >
                <b-form-input
                  v-model="account.deposit"
                  placeholder="First Name"
                  type="number"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <entity-select
              v-model="account.order"
              :filter="orderFilter"
            />

          </b-col>

          <b-col cols="12">
            <entity-select
              v-model="account.parent"
              :filter="parentFilter"
            />

          </b-col>

          <b-col cols="12">
            <app-collapse
              accordion
              type="border"
              class="mb-1"
            >
              <app-collapse-item title="Create order with custom price">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Price*"
                    rules="min:1"
                  >
                    <label>Price</label>
                    <b-form-input
                      v-model.number="account.price"
                      label="Price"
                      placeholder="Price"
                      type="number"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group>
                  <label class="mt-1">Product</label>
                  <product-select
                    v-model="account.product_id"
                  />
                </b-form-group>
              </app-collapse-item>
            </app-collapse>
          </b-col>
          <b-col cols="12">
            <b-form-group>
              <label>Execution</label>
              <b-form-select
                v-model="account.execution_type"
                :options="executions"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group class="mt-1">
              <label>Comment</label>
              <b-form-textarea
                v-model="account.comment"
                placeholder="Comment"
                rows="8"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group
              label="Send email"
            >
              <b-form-checkbox
                v-model="account.send_email"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
              :disabled="invalid || submitting || (!!!account.price && !!account.product_id) || (!!account.price && !!!account.product_id)"
            >
              <b-spinner
                v-if="submitting"
                small
                type="grow"
              />
              Create
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BForm,
  BSpinner,
  BFormCheckbox,
  BCard,
  BFormTextarea,
  BBadge,
  BFormSelect,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Swal from 'sweetalert2'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import AccountTypeSelect from './components/AccountTypeSelect.vue'
import CurrencySelect from './components/CurrencySelect.vue'
import { required, min } from '@/plugins/validations'
import AccountAPI from '@/api/account'
import ProductSelect from '@/components/ui/forms/ProductSelect.vue'
import ProductTreeSelectSingle from '@/components/ui/forms/ProductTreeSelectSingle.vue'
import EntitySelect from '@/components/ui/gqlforms/EntitySelect.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    BFormSelect,
    EntitySelect,
    ProductTreeSelectSingle,
    ProductSelect,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BForm,
    AccountTypeSelect,
    CurrencySelect,
    BSpinner,
    BFormCheckbox,
    BCard,
    BFormTextarea,
    BBadge,
    AppCollapse,
    AppCollapseItem,
  },
  props: {
    user: {
      type: Object,
      required: false,
      default: null,
    },
    autoRedirect: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      account: {
        user: this.user ? this.user.id : null,
        account_subtype: null,
        currency: null,
        deposit: 25000,
        order: null,
        parent: null,
        send_email: true,
        price: null,
        product_id: null,
        execution_type: null,
        comment: null,
      },
      required,
      min,
      invalid: false,
      submitting: false,
      product: null,
      executions: ['stp', 'ecn', 'stp-sf', 'ecn-sf'],
      userFilter: {
        name: 'user',
        label: 'User*',
        filterable: {
          type: 'entity', queryType: 'users', label: 'email', search: 'email', value: 'id',
        },
      },
      orderFilter: {
        name: 'order',
        label: 'Order',
        filterable: {
          type: 'entity', queryType: 'orders', label: 'order_number', search: 'order_number', value: 'id',
        },
      },
      parentFilter: {
        name: 'parent',
        label: 'Parent account',
        filterable: {
          type: 'entity', queryType: 'accounts', label: 'account_id', search: 'account_id', value: 'id',
        },
      },
    }
  },
  computed: {
    canSelectProduct() {
      return (this.product === null && this.account.account_subtype === null && this.account.currency === null) || !!this.product
    },
    canCreateCustomAccount() {
      return !this.product
    },
  },
  methods: {
    onSubmit() {
      Swal.fire({
        position: 'top-end',
        title: 'Is all data correct?',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Yes',
      }).then(result => {
        if (result.isConfirmed) {
          this.submitting = true
          AccountAPI.postCreateAccount(this.account)
            .then(response => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Account was created',
                  icon: 'WarningIcon',
                  variant: 'success',
                },
              })
              if (this.autoRedirect) this.$router.push({ name: 'account-detail', params: { id: response.account_id } })
              this.$emit('completed')
            }).catch(() => {
              this.submitting = false
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Unable to proceed',
                  icon: 'WarningIcon',
                  text: `There was an error in account setup. ${this.account.price !== null ? 'User have to completed his profile' : ''}`,
                  variant: 'danger',
                },
              })
            })
        }
      })
    },
    onSelectedProduct(product) {
      this.product = product
      this.account.account_subtype = this.product.accountSubtype.id
      this.account.currency = this.product.currency.id
      this.account.deposit = this.product.deposit
    },
    onDeselectedProduct() {
      this.product = null
      this.account.account_subtype = null
      this.account.currency = null
      this.account.deposit = 25000
    },
  },
}
</script>
