<template>
  <v-select
    :options="loading ? [] : options"
    label="text"
    :disabled="disabled"
    :placeholder="loading ? 'Loading...' : ''"
    :reduce="item => item.value"
    @input="onInput"
  />
</template>

<script>
import vSelect from 'vue-select'
import { get } from 'vuex-pathify'
import store from '@/store'

export default {
  components: {
    vSelect,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: true,
    }
  },
  computed: {
    ...get('account', ['subTypes']),
    options() {
      return this.subTypes.map(item => ({
        value: item.id,
        text: `(${item.server.platform.id}) ${item.account_type.name} ${item.name}`,
      }))
    },
  },
  mounted() {
    store.dispatch('account/getAccountSubtypes', { force: false }).then(() => {
      this.loading = false
    })
  },
  methods: {
    onInput(value) {
      this.$emit('input', value)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
