<template>
  <v-select
    :options="loading ? [] : options"
    label="name"
    :disabled="disabled"
    :placeholder="loading ? 'Loading...' : ''"
    :reduce="item => item.id"
    @input="onInput"
  />
</template>

<script>
import vSelect from 'vue-select'
import { get } from 'vuex-pathify'
import store from '@/store'

export default {
  components: {
    vSelect,
  },
  props: {
    accountSubtype: String,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: true,
    }
  },
  computed: {
    ...get('account', ['currencies']),
    options() {
      return this.accountSubtype ? this.currencies[this.accountSubtype] : []
    },
  },
  mounted() {
    store.dispatch('account/getCurrencies', { force: false }).then(() => {
      this.loading = false
    })
  },
  methods: {
    onInput(value) {
      this.$emit('input', value)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
