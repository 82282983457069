<template>
  <b-overlay
    :show="isBusy"
    rounded="sm"
  >
    <h3 v-if="account">
      Account ID: {{ account.account_id }}
    </h3>
    <b-form
      v-if="account"
      @submit="onSubmit"
    >
      <b-form-group
        label="Server name"
      >
        <custom-select
          v-model="form.server"
          module-name="serversList"
          label="name"
          :selected-item="{ id: account.server.id, name: `${account.server.name} - ${account.server.platform.id}` }"
          :reduce-func="serversReduceFunc"
        />
      </b-form-group>
      <hr>
      <div class="d-flex justify-content-between align-items-center">
        <b-button
          variant="primary"
          :disabled="disableSave"
          @click="onSubmit"
        >
          Save
        </b-button>
        <slot
          name="right-button"
          class="float-right ml-5"
        />
      </div>
    </b-form>
  </b-overlay>
</template>
<script>
import {
  BButton, BForm, BOverlay, BFormGroup,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import CustomSelect from '@/components/ui/forms/CustomSelect.vue'
import AccountAPI from '@/api/account'

export default {
  components: {
    CustomSelect, BForm, BOverlay, BButton, BFormGroup,
  },
  props: {
    account: Object,
  },
  data() {
    return {
      isBusy: false,
      form: {
        server: null,
      },
    }
  },
  computed: {
    disableSave() {
      return this.isBusy || !this.form.server
    },
  },
  mounted() {
    this.form.server = this.account.server.id
  },
  methods: {
    onSubmit() {
      AccountAPI.copyAccount(this.account.id, this.form).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Account was duplicated successfully',
            icon: 'WarningIcon',
            text: 'Account was created!',
            variant: 'success',
          },
        })
        this.$emit('close')
      }).catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Action failed.',
            icon: 'WarningIcon',
            text: 'Account duplication failed!',
            variant: 'danger',
          },
        })
      })
    },
    serversReduceFunc(servers) {
      return servers.map(server => ({ id: server.id, name: `${server.name} - ${server.platform.id}` }))
    },
  },
}
</script>
