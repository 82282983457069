<template>
  <accounts-table-gql :columns="columns" />
</template>
<script>
import AccountsTableGql from '@/components/entity/account-gql/AccountsTableGql.vue'
import {
  ACCOUNTS_SHORT, DEFAULT_FILTER_ACCOUNTS_TYPES, DRAWDOWN_TYPES_ENUM, EXECUTION_TYPES, PHASES, SPECIAL_OPTIONS,
} from '@/enums/accounts'

export default {
  components: { AccountsTableGql },
  data() {
    return {
      columns: [
        'show_details',
        {
          name: 'searchString', label: 'Quick search', filterable: 'like', visible: false,
        },
        {
          name: 'user',
          label: 'User',
        },
        {
          name: 'account_type_name',
          label: 'Account type',
          filterable: { type: 'multienum', enum: Object.keys(ACCOUNTS_SHORT) },
          default: DEFAULT_FILTER_ACCOUNTS_TYPES,
        },
        {
          name: 'account_subtype_name',
          label: 'Account phase',
          filterable: { type: 'multienum', enum: Object.keys(PHASES) },
        },
        {
          name: 'account_subtype_name',
          label: 'Phase',
        },
        {
          name: 'rules',
          sortable: true,
          label: 'ID|DD|TD|FW|W|PS',
        },
        {
          name: 'specialOption',
          label: 'Special options',
          filterable: { type: 'enum', enum: SPECIAL_OPTIONS },
        },
        {
          name: 'tags',
          label: 'Tags',
          filterable: {
            type: 'multientity', queryType: 'tags', value: 'id', label: 'name', search: 'name',
          },
        },
        {
          name: 'userTags',
          label: 'User tag',
          filterable: {
            type: 'multientity', queryType: 'tags', value: 'id', label: 'name', search: 'name',
          },
          visible: false,
        },
        {
          name: 'account_id',
          label: 'Account number',
        },
        {
          name: 'parent_id',
          label: 'Parent number',
        },
        {
          name: 'order_id',
          label: 'Order number',
        },
        {
          name: 'deposit',
          label: 'Deposit',
          filterable: 'range',
          default: [1, null],
          sortable: true,
        },
        {
          name: 'balance',
          label: 'Balance',
        },
        {
          name: 'createdAt',
          label: 'Created at',
          sortable: true,
        },
        {
          name: 'start_datetime',
          label: 'Start date',
          sortable: true,
        },
        {
          name: 'end_datetime',
          label: 'End date',
          sortable: true,
        },
        {
          name: 'close_datetime',
          label: 'Close date',
          filterable: 'daterange',
          sortable: true,
        },
        {
          name: 'state', label: 'State', filterable: { type: 'multienum', enum: ['failed', 'completed', 'active'] },
        },
        {
          name: 'rule_drawdown_type', label: 'DD type', filterable: { type: 'multienum', enum: DRAWDOWN_TYPES_ENUM }, visible: false,
        },
        {
          name: 'rule_max_days',
          label: 'Max days',
          filterable: 'eq',
          visible: false,
        },
        {
          name: 'rule_max_daily_drawdown',
          label: 'Max daily DD',
          filterable: 'eq',
          visible: false,
        },
        {
          name: 'rule_max_total_drawdown',
          label: 'Max initial DD',
          filterable: 'eq',
          visible: false,
        },
        {
          name: 'rule_min_trading_days',
          label: 'Min trading days',
          filterable: 'eq',
          visible: false,
        },
        {
          name: 'rule_min_profit',
          label: 'Min profit',
          filterable: 'eq',
          visible: false,
        },
        {
          name: 'rule_first_withdrawal_after',
          label: 'First withdrawal',
          filterable: 'eq',
          visible: false,
        },
        {
          name: 'rule_withdrawal_after',
          label: 'Withdrawal',
          filterable: 'eq',
          visible: false,
        },
        {
          name: 'userCountry',
          label: 'Country',
          filterable: {
            type: 'multientity', queryType: 'countries', value: 'id', label: 'name', search: 'name',
          },
          visible: false,
        },
        {
          name: 'execution_type',
          label: 'Execution type',
          filterable: { type: 'multienum', enum: EXECUTION_TYPES },
          visible: false,
        },
        {
          name: 'actions',
        },
      ],
    }
  },

}
</script>
