<template>
  <div>
    <b-row class="mb-2">
      <b-col
        lg="4"
        class="mb-1"
      >
        <strong>Full name: </strong>{{ consent.full_name }}
      </b-col>

      <b-col
        lg="4"
        class="mb-1"
      >
        <strong>Address: </strong>{{ consent.address }}
      </b-col>

      <b-col
        lg="4"
        class="mb-1"
      >
        <strong>Document type: </strong>{{ consent.doc_type }}
      </b-col>
      <b-col
        lg="4"
        class="mb-1"
      >
        <strong>Document number: </strong>{{ consent.doc_no }}
      </b-col>
      <b-col
        lg="4"
        class="mb-1"
      >
        <strong>Document expiration(UTC): </strong>{{ consent.doc_expiration }}
      </b-col>
      <b-col
        v-if="consent.acceptedAt"
        lg="4"
        class="mb-1"
      >
        <strong>Accepted at: </strong>{{ formatDateTime(consent.acceptedAt) }}
      </b-col>
      <b-col
        v-if="consent.declinedAt"
        lg="4"
        class="mb-1"
      >
        <strong>Declined at: </strong>{{ formatDateTime(consent.declinedAt) }}
      </b-col>
      <b-col
        lg="4"
        class="mb-1"
      >
        <strong>Ip: </strong>{{ consent.ip }}
      </b-col>
      <b-col
        lg="4"
        class="mb-1"
      >
        <strong>Created at: </strong>{{ formatDateTime(consent.createdAt) }}
      </b-col>
    </b-row>
    <b-row v-if="consent.files">
      <uploaded-document-images-galery
        :consent-id="consent.id"
        :images="consent.files"
        :img-height="250"
      />
    </b-row>
  </div>
</template>
<script>
import {
  BCol, BRow,
} from 'bootstrap-vue'
import { formatDateTime } from '@/plugins/formaters'
import UploadedDocumentImagesGalery from '@/components/forms/contract-consent/components/UploadedDocumentImagesGalery.vue'

export default {
  components: {
    UploadedDocumentImagesGalery,
    BCol,
    BRow,
  },
  props: {
    consent: Object,
  },
  methods: {
    formatDateTime,
  },
}
</script>
