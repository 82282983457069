<template>
  <div v-if="equities">
    <b-table
      :items="Object.values(equities.daily)"
      :fields="fields"
      striped
      responsive
      small
    >
      <template #cell(lowest)="data">
        Local: {{ data.item.lowest.local }}<br>
        Ext: {{ data.item.lowest.ext }}
        <div
          v-if="data.item.date === form.date && form.value"
          class="text-success"
        >
          New: {{ form.value }}
        </div>
      </template>
      <template #cell(highest)="data">
        Local: {{ data.item.highest.local }}<br>
        Ext: {{ data.item.highest.ext }}
      </template>
      <template #cell(close)="data">
        {{ data.item.close }}
        <div
          v-if="data.item.date === form.date && form.value && form.value > data.item.close"
          class="text-success"
        >
          New: {{ form.value }}
        </div>
      </template>
      <template #cell(close_balance)="data">
        {{ data.item.close_balance }}
        <div
          v-if="data.item.date === form.date && form.value && form.value > data.item.close_balance"
          class="text-success"
        >
          New: {{ form.value }}
        </div>
      </template>
    </b-table>
    <p class="font-weight-bold">
      Overall lowest: {{ equities.total }}
      <span
        v-if="form.set_overall && form.value"
        class="text-success"
      >New value: {{ form.value }}</span>
    </p>
    <b-form-group
      label="Date"
    >
      <v-select
        v-model="form.date"
        :options="dates"
      />
    </b-form-group>
    <b-form-group
      label="Set overall lowest"
    >
      <b-form-checkbox
        v-model="form.set_overall"
      />
    </b-form-group>
    <b-form-group
      label="New lowest value"
    >
      <b-form-input
        v-model.number="form.value"
      />
    </b-form-group>

    <b-button @click="onSubmit">
      Set values and reset account
    </b-button>
  </div>
</template>
<script>
import Ripple from 'vue-ripple-directive'
import {
  BFormGroup, BButton, BFormInput, BTable, BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import AccountAPI from '@/api/account'

export default {
  directives: {
    Ripple,
  },
  components: {
    vSelect,
    BFormGroup,
    BButton,
    BFormInput,
    BTable,
    BFormCheckbox,
  },
  props: {
    accountId: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      form: {
        set_overall: false,
        value: null,
        date: null,
      },
      date: null,
      equities: null,
      fields: ['date', 'lowest', 'highest', 'open', 'open_balance', 'close', 'close_balance'],
    }
  },
  computed: {
    dates() {
      return Object.keys(this.equities.daily)
    },
  },
  mounted() {
    AccountAPI.getAccountResetEquities(this.accountId).then(data => {
      this.equities = data
    })
  },
  methods: {
    onSubmit() {
      if (this.form.value && this.form.value > 0) {
        AccountAPI.postAccountResetEquities(this.accountId, this.form).then(() => {
          this.$emit('submit')
        })
      }
    },
  },
}
</script>
