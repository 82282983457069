<template>
  <b-overlay :show="loading">
    <vue-gallery
      id="consent-gallery"
      :images="files"
      :index="index"
      @close="index = null"
    />
    <b-row>
      <div
        v-for="(image, imageIndex, key) in files"
        :key="key"
      >
        <b-img
          :height="imgHeight"
          :src="image"
          class="m-1"
          @click="index = (imageIndex + key)"
        />
      </div>
    </b-row>
  </b-overlay>
</template>
<script>
import {
  BRow, BImg, BOverlay,
} from 'bootstrap-vue'
import VueGallery from 'vue-gallery'
import { formatDateTime } from '@/plugins/formaters'
import ConsentAPI from '@/api/contract-consent'

export default {
  components: {
    VueGallery,
    BRow,
    BImg,
    BOverlay,
  },
  props: {
    consentId: String,
    images: {
      type: Array,
      default: () => [],
    },
    imgHeight: {
      type: Number,
      default: 400,
    },
  },
  data() {
    return {
      files: [],
      index: null,
      loading: false,
    }
  },
  watch: {
    images: {
      immediate: true,
      deep: true,
      handler() {
        this.loadData()
      },
    },
  },
  methods: {
    formatDateTime,
    async loadData() {
      this.loading = true
      const files = []
      if (this.images.length > 0) {
        // eslint-disable-next-line no-restricted-syntax
        for (const file of this.images) {
          ConsentAPI.getConsentImage(this.consentId, file.type).then(f => files.push('data:image/jpg;base64,'.concat(f)))
        }
        this.files = files
      }
      this.loading = false
    },
  },
}
</script>
