<template>
  <b-overlay
    :show="isBusy"
    rounded="sm"
  >
    <b-form @submit="onSubmit">
      <b-form-row>
        <b-col
          md="6"
          xs="12"
        >
          <label>{{ itemsName }}</label>
          <b-form-textarea
            v-model="form.items"
            :placeholder="itemsName"
            rows="50"
          />
        </b-col>
        <b-col
          md="6"
          xs="12"
        >
          <b-form-group
            label="Tags"
          >
            <b-badge
              v-for="(item, index) in form.tags"
              :key="index"
              class="m-50"
              :variant="item.color"
            >
              {{ item.name }}
              <b-button
                class="btn-icon rounded-circle"
                size="sm"
                :variant="item.color"
                @click="removeTag(item.id)"
              >
                <feather-icon icon="XIcon" />
              </b-button>
            </b-badge>
            <custom-select
              module-name="tagsList"
              label="name"
              not-reduce-by-id
              @input="addTag"
            />
          </b-form-group>
          <b-form-group v-if="showPurchaseDisabledOption">
            <b-form-checkbox v-model="form.purchaseDisabled">
              Purchase disabled
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-form-row>

      <hr>
      <div class="d-flex justify-content-between">
        <b-button
          variant="primary"
          :disabled="form.tags.length === 0 || form.items.length === 0"
          @click="onSubmit(false)"
        >
          Save
        </b-button>

        <b-button
          v-if="showAssignToUsersBtn"
          variant="secondary"
          :disabled="form.tags.length === 0 || form.items.length === 0"
          @click="onSubmit(true)"
        >
          Save & assign tags to users
        </b-button>
      </div>
    </b-form>
  </b-overlay>
</template>
<script>
import {
  BButton, BOverlay, BForm, BFormRow, BCol, BFormGroup, BBadge, BFormTextarea, BFormCheckbox,
} from 'bootstrap-vue'
import CustomSelect from '@/components/ui/forms/CustomSelect.vue'

export default {
  components: {
    BButton,
    BOverlay,
    BForm,
    BFormRow,
    BCol,
    BFormGroup,
    BBadge,
    BFormCheckbox,
    BFormTextarea,
    CustomSelect,
  },
  props: {
    itemsName: String,
    showAssignToUsersBtn: {
      type: Boolean,
      default: false,
    },
    showPurchaseDisabledOption: {
      type: Boolean,
      default: false,
    },
    isBusy: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        items: '',
        tags: [],
        purchaseDisabled: false,
      },
    }
  },
  methods: {
    onSubmit(assignTagsToUser) {
      const items = this.form.items
        .replace(/ +/g, '')
        .replace(/\n/g, ',')
        .replace(/\r/g, ',')
        .replace(/\t/g, ',')
        .split(',')

      if (items.length < 1 || this.form.tags.length < 1) return

      this.$emit('submit', {
        tags: this.form.tags, items: items.filter(item => (item.length > 0)), assignTagsToUser, purchaseDisabled: this.form.purchaseDisabled,
      })
    },
    addTag(item) {
      if (!this.form.tags.find(tag => tag.id === item.id)) this.form.tags.push(item)
    },
    removeTag(id) {
      this.form.tags = this.form.tags.filter(item => item.id !== id)
    },
  },
}
</script>
