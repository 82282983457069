<template>
  <b-overlay :show="loading">
    <b-card v-if="account_data">
      <h3>Contract info </h3>
      <b-row>
        <b-col>
          <strong>Contract signed at: </strong>{{ account_data.signedAt ? formatDateTime(account_data.signedAt) : 'Contract was not signed yet' }}
        </b-col>
        <b-col>
          <strong>Contract confirmed at: </strong>{{ account_data.confirmedAt ? formatDateTime(account_data.confirmedAt) : 'Contract was not confirmed yet' }}
        </b-col>
        <b-col>
          <b-link
            :href="`${pandaPrefix}${account_data.doc_id}`"
            target="_blank"
          >
            <b-button
              v-b-tooltip.hover.top="'Show contract'"
              variant="outline-primary"
              class="btn-icon ml-1"
            >
              <feather-icon
                icon="FileIcon"
              />
            </b-button>
          </b-link>

          <b-button
            v-b-tooltip.hover.top="'Download contract pdf'"
            variant="outline-primary"
            class="btn-icon ml-1"
            :disabled="!!!account_data.signedAt"
            @click="onDownloadContract"
          >
            <feather-icon icon="DownloadIcon" />
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <hr>

    <app-collapse
      v-for="(consent, index, key) in consents"
      :key="key"
    >
      <h3>Contract consents</h3>
      <app-collapse-item
        is-visible
        class="my-1"
        :title=" '#'+ index"
      >
        <contract-consent :consent="consent" />
      </app-collapse-item>
    </app-collapse>
    <hr>

    <app-collapse v-if="account_data">
      <app-collapse-item
        class="my-1"
        title="User verification data"
      >
        <veriff-detail
          v-for="(veriff, key) in account_data.user.verifications"
          :key="key"
          :item="veriff"
        >
          <hr>
        </veriff-detail>
      </app-collapse-item>
    </app-collapse>

    <app-collapse v-if="account_data">
      <app-collapse-item
        v-if="account_data.user.verifications.length > 0"
        class="my-1"
        title="User verification images"
      >
        <veriff-images :verifications="account_data.user.verifications" />
      </app-collapse-item>
    </app-collapse>

  </b-overlay>
</template>
<script>
import {
  BRow, BCol, BCard, BLink, VBTooltip, BButton, BOverlay,
} from 'bootstrap-vue'
import { dispatch, get } from 'vuex-pathify'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { formatDateTime } from '@/plugins/formaters'
import { PANDADOC_URL_PREFIX } from '@/enums/accounts'
import ContractConsent from '@/components/page/contract-veriff/components/ContractConsent.vue'
import VeriffDetail from '@/components/page/JsonRecursiveList.vue'
import VeriffImages from '@/components/page/contract-veriff/components/VeriffImages.vue'
import PandadocAPI from '@/api/pandadoc'

export default {
  components: {
    ContractConsent,
    AppCollapse,
    AppCollapseItem,
    VeriffDetail,
    VeriffImages,
    BOverlay,
    BCol,
    BRow,
    BCard,
    BLink,
    BButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    accountId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      pandaPrefix: PANDADOC_URL_PREFIX,
      index: null,
      loading: false,
    }
  },
  computed: {
    ...get('contractConsentsList', { consents: 'data', status: 'status' }),
    ...get('accountsList@row', { account_data: 'account_data' }),
  },
  watch: {
    accountId: {
      immediate: true,
      deep: true,
      handler() {
        this.loadData()
      },
    },
  },
  methods: {
    formatDateTime,
    async loadData() {
      this.loading = true
      if (this.accountId !== null) {
        await dispatch('contractConsentsList/setFilters', { search: this.accountId })
        if (this.status === 'initial') {
          await dispatch('contractConsentsList/loadAll')
        } else {
          await dispatch('contractConsentsList/reloadAll')
        }
        await dispatch('accountsList/get', this.accountId)
      }
      this.loading = false
    },
    onDownloadContract() {
      if (!this.account_data) return

      PandadocAPI.downloadAgreement(this.account_data.id, this.account_data.user.email, this.accountId).catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Download failed',
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      })
    },
  },
}
</script>
