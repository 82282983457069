<template>
  <b-overlay
    :show="isBusy"
    rounded="sm"
  >
    <b-form @submit="onSubmit">
      <b-form-row>
        <b-form-textarea
          v-model="items"
          :placeholder="itemsName"
          rows="30"
        />
      </b-form-row>
      <hr>
      <b-button
        variant="primary"
        :disabled="items.length === 0"
        @click="onSubmit"
      >
        Submit
      </b-button>
    </b-form>
  </b-overlay>
</template>

<script>
import {
  BButton, BForm, BFormRow, BFormTextarea, BOverlay,
} from 'bootstrap-vue'

export default {
  components: {
    BButton,
    BForm,
    BFormRow,
    BFormTextarea,
    BOverlay,
  },
  props: {
    itemsName: String,
    isBusy: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      items: '',
    }
  },
  methods: {
    onSubmit() {
      const items = this.items
        .replace(/ +/g, '')
        .replace(/\n/g, ',')
        .replace(/\r/g, ',')
        .replace(/\t/g, ',')
        .split(',')
      if (items.length < 1) return

      this.$emit('submit', items.filter(item => (item.length > 0)))
    },
  },
}
</script>
