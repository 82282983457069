<template>
  <ul>
    <li v-if="typeof item === 'string' || typeof item === 'boolean' || typeof item === 'number'">
      {{ item }}
    </li>
    <li v-else-if="item instanceof Object && Object.values(item).length === 1 && !(Object.values(item)[0] instanceof Array) ">
      <strong>{{ Object.keys(item)[0] }}: </strong>{{ Object.values(item)[0] }}
    </li>
    <li
      v-for="(i, key) in item"
      v-else
      :key="key"
    >
      <div v-if="i instanceof Array && typeof i !== 'string'">
        <strong>{{ key }}: </strong>
        <node
          v-for="(f, index) in i"
          :key="f+index"
          :item="f"
        />
      </div>
      <div v-else-if="(((i instanceof Object && Object.values(i).length === 0 ) || typeof i === 'string') && !(i instanceof Array) )">
        <strong>{{ key }}: </strong>{{ i }}

      </div>
      <div
        v-else
      >
        <strong>
          {{ key }}: </strong> <node :item="i" />
      </div>
    </li>
  </ul>
</template>
<script>

export default {
  name: 'Node',
  props: {
    item: [Object, Array, String, Boolean, Number],
  },
}
</script>
