import axiosInstance from '@/plugins/axios'

export default {

  createAccountWithOrder(orderId) {
    const url = `admin/orders/${orderId}/create-account`

    return axiosInstance.post(url)
      .then(response => response.data)
  },

  generateOrderReport(orderNumber) {
    const url = `admin/orders/${orderNumber}/report`

    return axiosInstance.get(url, {
      responseType: 'blob',
    })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${orderNumber}.pdf`)
        document.body.appendChild(link)
        link.click()
      })
  },

  generateOrderDisputeReport(orderId, orderNumber, files, disputeType, cardNumber, cardType) {
    const url = `admin/orders/${orderId}/dispute-report`

    const formData = new FormData()
    formData.append('disputeType', disputeType)
    formData.append('cardNumber', cardNumber)
    formData.append('cardType', cardType)
    files.forEach(f => {
      formData.append(f.name, f.file.file)
    })

    return axiosInstance.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      responseType: 'blob',
    }).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `Dispute_report_${orderNumber}.pdf`)
      document.body.appendChild(link)
      link.click()
    })
  },

  fetchAllProducts() {
    const url = 'admin/products'

    return axiosInstance.get(url)
      .then(response => response.data)
  },

  postTagsToOrders(data) {
    const url = 'admin/orders/tags'

    return axiosInstance.post(url, data)
      .then(response => response.data)
  },

  removeOrder(id) {
    const url = `admin/orders/${id}`

    return axiosInstance.delete(url)
      .then(response => response.data)
  },
}
